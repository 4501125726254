var exports = {};

(function (global, factory) {
  factory(exports);
})(exports, function (exports) {
  'use strict';

  var fp = typeof window !== "undefined" && window.flatpickr !== undefined ? window.flatpickr : {
    l10ns: {}
  };
  var Czech = {
    weekdays: {
      shorthand: ["Ne", "Po", "\xDAt", "St", "\u010Ct", "P\xE1", "So"],
      longhand: ["Ned\u011Ble", "Pond\u011Bl\xED", "\xDAter\xFD", "St\u0159eda", "\u010Ctvrtek", "P\xE1tek", "Sobota"]
    },
    months: {
      shorthand: ["Led", "\xDAn", "B\u0159e", "Dub", "Kv\u011B", "\u010Cer", "\u010Cvc", "Srp", "Z\xE1\u0159", "\u0158\xEDj", "Lis", "Pro"],
      longhand: ["Leden", "\xDAnor", "B\u0159ezen", "Duben", "Kv\u011Bten", "\u010Cerven", "\u010Cervenec", "Srpen", "Z\xE1\u0159\xED", "\u0158\xEDjen", "Listopad", "Prosinec"]
    },
    firstDayOfWeek: 1,
    ordinal: function () {
      return ".";
    },
    rangeSeparator: " do ",
    weekAbbreviation: "T\xFDd.",
    scrollTitle: "Rolujte pro zm\u011Bnu",
    toggleTitle: "P\u0159epnout dopoledne/odpoledne",
    amPM: ["dop.", "odp."],
    yearAriaLabel: "Rok",
    time_24hr: true
  };
  fp.l10ns.cs = Czech;
  var cs = fp.l10ns;
  exports.Czech = Czech;
  exports.default = cs;
  Object.defineProperty(exports, "__esModule", {
    value: true
  });
});

export default exports;
export const Czech = exports.Czech,
      __esModule = exports.__esModule;